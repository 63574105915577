$(document).ready(function() {
    AOS.init({
        duration: 500,
        easing: 'ease',
    });

    $('.select2').select2({
        sorter: data => data.sort((a, b) => $.trim(a.text).localeCompare($.trim(b.text))),
        theme: 'bootstrap4',
        width: '100%',
        dropdownAutoWidth: true,
        placeholder: "Select an option.."
    });

    $('.select2-sm').select2({
        sorter: data => data.sort((a, b) => $.trim(a.text).localeCompare($.trim(b.text))),
        theme: 'bootstrap4',
        width: '100%',
        dropdownAutoWidth: true,
        containerCssClass: "select2-sm"
    });

    $('.select2.select2-no-search').select2({
        sorter: data => data.sort((a, b) => $.trim(a.text).localeCompare($.trim(b.text))),
        theme: 'bootstrap4',
        width: '100%',
        dropdownAutoWidth: true,
        minimumResultsForSearch: Infinity
    });

    $('.select2, .select2-sm, .select2.select2-no-search').on('click', function() {
        $('input.select2-search__field')[0].focus();
    });

    $('.datepicker').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        minYear: 1900,
        locale: {
            format: 'YYYY-MM-DD'
        },
        autoUpdateInput: false,
        autoApply: true
    }, function(start, end, label) {
        this.element.val(start.format('YYYY-MM-DD'));
    });

    $('.datepicker').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY-MM-DD'));
    });

    $('.datepicker').attr('autocomplete', 'off');

    $('.date-range-picker').daterangepicker({
        opens: 'left',
        showDropdowns: true,
        minYear: 1900,
        locale: {
            format: 'YYYY-MM-DD'
        },
        autoUpdateInput: false
    }, function(start, end) {
        this.element.val(start.format('YYYY-MM-DD') + ' • ' + end.format('YYYY-MM-DD'));
        //console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
    });

    $('.datetimepicker').daterangepicker({
        singleDatePicker: true,
        timePicker: true,
        showDropdowns: true,
        minYear: 2010,
        autoUpdateInput: false,
        locale: {
            format: 'YYYY-MM-DD hh:mm A'
        }
    }, function(start, end) {
        this.element.val(start.format('YYYY-MM-DD hh:mm A'));
    });

    $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY-MM-DD hh:mm A'));
    });

    $('.datetimepicker').attr('autocomplete', 'off');

    $('.timepicker').daterangepicker({
        singleDatePicker: true,
        timePicker: true,
        timePicker24Hour: false,
        timePickerIncrement: 1,
        locale: {
            format: 'HH:mm'
        }
    }).on('show.daterangepicker', function(ev, picker) {
        picker.container.find(".calendar-table").hide();
    });
    $('.timepicker').attr('autocomplete', 'off');
    //$('.timepicker').val('').trigger('change');

    $('.form').on('submit', function() {
        event.preventDefault();
        var form = this;
        console.log(this);
        var exclude = ['diagnostic-form', 'consultation-form'];
        if (!exclude.includes($(this).attr('id'))) {
            if ($(form).valid()) {
                $(':submit', form).attr('disabled', 'disabled');
                form.submit();
            }
        }
    });

    $(".custom-file-input").on("change", function() {
        var fileName = $(this).val().split('\\').pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });

    $(".toggle-password").click(function() {
        $(this).toggleClass("fa-eye-slash fa-eye");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    $('.fit').each(function(i) {
        var fs = $("body").css('font-size').replace('px', '');
        var dw = $(this).width();
        var text = $(this).children();
        while (text.width() > dw) {
            text.css('font-size', fs -= 0.5);
        }
    });

    $('.dropdown-menu').on("click.bs.dropdown", function(e) {
        e.stopPropagation();
    });

    $("#wizard").on("showStep", function(e, anchorObject, stepIndex, stepDirection) {
        if ($('.sw-btn-prev').hasClass('disabled')) {
            $('.sw-btn-prev').addClass('d-none');
        } else {
            $('.sw-btn-prev').removeClass('d-none');
        }
        if ($('.sw-btn-next').hasClass('disabled')) {
            $('.sw-btn-next').addClass('d-none');
        } else {
            $('.sw-btn-next').removeClass('d-none');
        }
    });

    $.fn.extend({
        donetyping: function(callback, timeout) {
            timeout = timeout || 1e3;
            var timeoutReference,
                doneTyping = function(el) {
                    if (!timeoutReference) return;
                    timeoutReference = null;
                    callback.call(el);
                };
            return this.each(function(i, el) {
                var $el = $(el);
                // Chrome Fix (Use keyup over keypress to detect backspace)
                // thank you @palerdot
                $el.is(':input') && $el.on('keyup keypress paste search change', function(e) {
                    // This catches the backspace button in chrome, but also prevents
                    // the event from triggering too preemptively. Without this line,
                    // using tab/shift+tab will make the focused element fire the callback.
                    if (e.type == 'keyup' && e.keyCode != 8) return;

                    // Check if timeout has been set. If it has, "reset" the clock and
                    // start over again.
                    if (timeoutReference) clearTimeout(timeoutReference);
                    timeoutReference = setTimeout(function() {
                        // if we made it here, our timeout has elapsed. Fire the
                        // callback
                        doneTyping(el);
                    }, timeout);
                }).on('blur', function() {
                    // If we can, fire the event since we're leaving the field
                    doneTyping(el);
                });
            });
        }
    });
});